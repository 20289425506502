import * as React from 'react'
import { IProductUseCase } from '../../../types/Product'
import ColoredBox from '../../content/ColoredBox'
import ContentImage from '../../content/ContentImage'
import RenderHtml from '../../content/RenderHtml'
import * as styles from './UseCaseBox.module.scss'

const UseCaseBox: React.FunctionComponent<UseCaseBoxProps> = (props: UseCaseBoxProps) => {
    const { useCase, pdftColor } = props

    if (useCase.icon) {
        return (
            <div className={styles.iconBox}>
                <div className={`${styles.head} ${styles[pdftColor]}`}>
                    <ContentImage image={useCase.icon} fluid={true} />
                    <h3>{useCase.title}</h3>
                </div>
                <RenderHtml className={styles.body} html={useCase.text || ''} />
            </div>
        )
    } else {
        return (
            <ColoredBox rounded={true} pdftColor={pdftColor} className="h-100 text-center">
                <>
                    <h3>{useCase.title}</h3>
                    <RenderHtml html={useCase.text || ''} />
                </>
            </ColoredBox>
        )
    }
}

interface UseCaseBoxProps {
    useCase: IProductUseCase
    pdftColor: string
}

export default UseCaseBox
