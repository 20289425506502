import * as React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import AvailabilityBar from '../components/content/availabilitybar/AvailabilityBar'
import ColoredBox from '../components/content/ColoredBox'
import ContentButton from '../components/content/ContentButton'
import ContentImage from '../components/content/ContentImage'
import ImageLink from '../components/content/ImageLink'
import SampleBox from '../components/content/sample/SampleBox'
import withTranslations from '../components/hoc/withTranslations'
import Banner from '../components/layout/Banner'
import PdfSamplesImage from '../images/pdf-manuals-pdf-samples.png'
import MainLayout from '../layouts/MainLayout'
import ImgThreeHeights from '../images/logo_3-heights_70-70.png'
import ImgPdfaConform from '../images/logo_pdf-a_70-70.png'
import PageContext from '../types/PageContext'
import { IProductTemplateData } from '../types/Product'
import Translation from '../types/Translation'
import UseCaseBox from '../components/template/productpage/UseCaseBox'
import BlogBox from '../components/content/BlogBox'
import GridLayoutRenderer from '../components/content/GridLayoutRenderer'
import { ISuccessStoryTeaser } from '../types/SuccessStory'
import RenderHtml from '../components/content/RenderHtml'
import AnyLink from '../components/AnyLink'

class ProductPageTemplate extends React.Component<ProductPageProps, ProductPageState> {
    public render() {
        const { pageContext, t } = this.props
        const { templateData, grids } = pageContext

        if (!templateData) {
            return null
        }

        const {
            product,
            banner,
            title,
            intro,
            productUseCases,
            successStories,
            productIllustration,
            features,
            productAvailability,
            conformance,
            supportedFormats,
            documentations,
            checklist,
            functionalityGraphic,
            areasOfUseTitleSuffix,
            areasOfUse,
            sampleMainUseCase,
            blogEntries,
            layout,
            urls,
        } = templateData

        const documentationManuals =
            product.code === 'VIEWWEB'
                ? documentations.filter((d) => d.type === 'manual' && d.filename !== 'PdfViewingSDK_C.pdf')
                : documentations.filter((d) => d.type === 'manual')
        const documentationReferences = documentations.filter((d) => d.type === 'reference')
        const gridsBanner = grids?.filter((g) => g.slot === 'banner')

        const bannerElement = (
            <>
                {gridsBanner &&
                    gridsBanner.map((grid) => (
                        <GridLayoutRenderer key={`banner_${grid.directusId}`} pageContext={pageContext} rootItem={grid} />
                    ))}
                {!gridsBanner?.length && banner && (
                    <Banner header={banner.title} headerText={banner.text || undefined} pdftColor={product.color} />
                )}
            </>
        )

        let evalFreeButton
        if (urls.evalPage) {
            evalFreeButton =
                product.freeTrial === 'unlimited'
                    ? t('template', 'product.button.evalFree.label')
                    : t('template', 'product.button.evalFree30days.label')
        }

        return (
            <MainLayout pageContext={pageContext} banner={bannerElement}>
                <Container id="overview">
                    {intro && (
                        <Row>
                            <Col sm={12} className="mb-4">
                                <h1>{title}</h1>
                                <RenderHtml html={intro} />
                            </Col>
                        </Row>
                    )}
                    {layout === 'convsrv' && productIllustration && (
                        <Row>
                            <Col sm={12} className="text-center">
                                <ContentImage image={productIllustration} fluid={true} />
                            </Col>
                        </Row>
                    )}
                    {productUseCases.length > 0 && (
                        <Row className="mb-4">
                            {productUseCases.map((useCase, i) => (
                                <Col sm={4} key={i}>
                                    <UseCaseBox useCase={useCase} pdftColor="gray" />
                                </Col>
                            ))}
                        </Row>
                    )}
                </Container>
                {layout === 'legacy' && (
                    <Container>
                        <Row>
                            <Col sm={4}>
                                {evalFreeButton && (
                                    <ContentButton block={true} pdftColor={product.color} to={urls.evalPage}>
                                        {evalFreeButton}
                                    </ContentButton>
                                )}
                            </Col>
                            <Col sm={4}>
                                {urls.quoteRequestPage && (
                                    <ContentButton block={true} pdftColor={product.color} to={urls.quoteRequestPage}>
                                        {t('template', 'product.button.quoterequest.label')}
                                    </ContentButton>
                                )}
                            </Col>
                            <Col sm={4}>
                                {urls.onlineDemo && (
                                    <ContentButton block={true} pdftColor={product.color} href={urls.onlineDemo} target="_blank">
                                        {t('template', 'product.button.testonline.label')}
                                    </ContentButton>
                                )}
                            </Col>
                        </Row>
                    </Container>
                )}
                {successStories.length > 0 && (
                    <ColoredBox fullWidth={true} pdftColor="blue">
                        <Row id="successstories">
                            {successStories?.slice(0, 2).map((story: ISuccessStoryTeaser, i) => (
                                <Col sm={6} key={i}>
                                    <Row className="h-100">
                                        <Col sm={4} className="align-self-center">
                                            {story.customerLogo && (
                                                <ImageLink to={story.successStoryUrl} image={story.customerLogo} center={true} />
                                            )}
                                        </Col>
                                        <Col sm={8}>
                                            <p className="h3">{story.title}</p>
                                            <RenderHtml html={story.teaser} />
                                        </Col>
                                    </Row>
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            {successStories?.slice(0, 2).map((story: ISuccessStoryTeaser, i) => (
                                <Col sm={6} key={i}>
                                    <Row className="h-100">
                                        <Col sm={{ span: 8, offset: 4 }} className="pt-2 pb-2">
                                            <AnyLink to={story.successStoryUrl} hasIcon={true}>
                                                {t('template', 'general.link.text.readmore')}
                                            </AnyLink>
                                        </Col>
                                    </Row>
                                </Col>
                            ))}
                        </Row>
                    </ColoredBox>
                )}
                {layout === 'legacy' && productIllustration && (
                    <Container>
                        <Row>
                            <Col sm={12} className="text-center">
                                <ContentImage image={productIllustration} fluid={true} />
                            </Col>
                        </Row>
                    </Container>
                )}
                {layout === 'legacy' && (
                    <Container id="features">
                        <Row>
                            <Col sm={8}>
                                <Row>
                                    <Col sm={12}>
                                        {features && <RenderHtml className="mb-3" html={features} />}
                                        {functionalityGraphic && <ContentImage image={functionalityGraphic} fluid={true} />}
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={4}>
                                {productAvailability && (
                                    <Row>
                                        <Col sm={12}>
                                            <Row>
                                                <Col sm={12}>
                                                    <AvailabilityBar barItems={productAvailability.productVariants} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <AvailabilityBar barItems={productAvailability.osVariants} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )}
                                {conformance && (
                                    <Row>
                                        <Col sm={12}>
                                            <h3 className="underline">Conformance</h3>
                                            <RenderHtml html={conformance} />
                                        </Col>
                                    </Row>
                                )}
                                {(product.pdfaConform || product.family === '3heights') && (
                                    <Row>
                                        <Col sm={12}>
                                            {product.family === '3heights' && (
                                                <Image
                                                    alt={t('template', 'threeheights.logo.alt')}
                                                    src={ImgThreeHeights}
                                                    className="border rounded"
                                                />
                                            )}
                                            {product.pdfaConform && (
                                                <Image alt="PDF/A compliant" src={ImgPdfaConform} className="border rounded" />
                                            )}
                                        </Col>
                                    </Row>
                                )}
                                {supportedFormats && (
                                    <Row>
                                        <Col sm={12}>
                                            <h3 className="underline">{t('template', 'product.title.supportedformats')}</h3>
                                            <RenderHtml html={supportedFormats} />
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col sm={4}>
                                        <Image fluid={true} src={PdfSamplesImage} />
                                    </Col>
                                    <Col sm={8}>
                                        {sampleMainUseCase && (
                                            <div className="mt-2 mb-3">
                                                <ContentButton
                                                    href="#sample"
                                                    tooltip={t('template', 'product.button.samplecode.tooltip')}
                                                    pdftColor="mustardyellow"
                                                    block={true}
                                                >
                                                    {t('template', 'product.button.samplecode.label')}
                                                </ContentButton>
                                            </div>
                                        )}

                                        {documentationManuals && documentationManuals.length > 0 && (
                                            <div id="manuals">
                                                <p>
                                                    <strong>{t('template', 'product.title.manual')}</strong>
                                                </p>
                                                <p>
                                                    {documentationManuals.map((doc, i) => (
                                                        <React.Fragment key={i}>
                                                            {i > 0 && ' | '}

                                                            <AnyLink
                                                                to={`/public/downloads/manuals/${doc.filename}`}
                                                                hasIcon={true}
                                                            >
                                                                {doc.title}
                                                            </AnyLink>
                                                        </React.Fragment>
                                                    ))}
                                                </p>
                                            </div>
                                        )}

                                        {documentationReferences && documentationReferences.length > 0 && (
                                            <div>
                                                <p>
                                                    <strong>{t('template', 'product.title.references')}</strong>
                                                </p>
                                                <p>
                                                    {documentationReferences.map((doc, i) => (
                                                        <React.Fragment key={i}>
                                                            {i > 0 && ' | '}
                                                            <AnyLink to={`/public/downloads/${doc.filename}`}>
                                                                {doc.title}
                                                            </AnyLink>
                                                        </React.Fragment>
                                                    ))}
                                                </p>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                )}
                <Container id="areasofuse">
                    <Row>
                        <Col sm={checklist ? 8 : 12}>
                            {areasOfUse.length > 0 && (
                                <Row>
                                    <Col sm={12}>
                                        <h2>
                                            {t('template', 'product.page.title.areasofuse')}
                                            {areasOfUseTitleSuffix && ` - ${areasOfUseTitleSuffix}`}
                                        </h2>
                                        {areasOfUse.map((area, i) => (
                                            <ColoredBox rounded={true} pdftColor={product.color} key={i}>
                                                <h3>{area.title}</h3>
                                                <RenderHtml html={area.text || ''} />
                                            </ColoredBox>
                                        ))}
                                        <ContentButton
                                            to={urls.contactPage}
                                            tooltip={t('template', 'product.button.contact.tooltip')}
                                        >
                                            {t('template', 'product.button.contact.label')}
                                        </ContentButton>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                        {checklist && (
                            <Col sm={4}>
                                <Row>
                                    <Col sm={12}>
                                        <RenderHtml html={checklist} />
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                </Container>
                {sampleMainUseCase && (
                    <div id="sample">
                        <SampleBox
                            productCode={product.code}
                            productShortname={product.shortname}
                            useCase={sampleMainUseCase}
                            toMoreSamples={urls.samplePage}
                            firstTabActive={true}
                        />
                    </div>
                )}
                {grids
                    ?.filter((g) => g.slot === 'content5')
                    .map((grid) => (
                        <GridLayoutRenderer key={`content5_${grid.directusId}`} pageContext={pageContext} rootItem={grid} />
                    ))}
                {layout === 'convsrv' && (
                    <Container>
                        <Row>
                            <Col>
                                {urls.evalPage && (
                                    <ColoredBox fullWidth={true} spaceBottom={true} padding={false}>
                                        <Container className="mt-0">
                                            <Row className="align-items-end">
                                                <Col sm="8" className="p-0">
                                                    <h3 className="text-uppercase">
                                                        {t('template', 'product.convsrv.evalFree.title')}
                                                    </h3>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: t('template', 'product.convsrv.evalFree.description') || '',
                                                        }}
                                                    />
                                                </Col>
                                                <Col className="p-0 text-center">
                                                    <ContentButton pdftColor={product.color} to={urls.evalPage} className="mb-0">
                                                        {evalFreeButton}
                                                    </ContentButton>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </ColoredBox>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {urls.quoteRequestPage && (
                                    <ColoredBox fullWidth={true} spaceBottom={true} padding={false}>
                                        <Container className="mt-0">
                                            <Row className="align-items-end">
                                                <Col sm="8" className="p-0">
                                                    <h3 className="text-uppercase">
                                                        {t('template', 'product.convsrv.quoterequest.title')}
                                                    </h3>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                t('template', 'product.convsrv.quoterequest.description') || '',
                                                        }}
                                                    />
                                                </Col>
                                                <Col className="p-0 text-center">
                                                    <ContentButton
                                                        pdftColor={product.color}
                                                        to={urls.quoteRequestPage}
                                                        className="mb-0"
                                                    >
                                                        {t('template', 'product.button.quoterequest.label')}s
                                                    </ContentButton>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </ColoredBox>
                                )}
                            </Col>
                        </Row>
                    </Container>
                )}
                {blogEntries.length > 0 && (
                    <Container id="blog">
                        <Row>
                            {blogEntries.map((blogEntry) => (
                                <Col sm={6} key={blogEntry.url}>
                                    <BlogBox title={blogEntry.title} img={blogEntry.image} href={blogEntry.url}>
                                        {blogEntry.teaser}
                                    </BlogBox>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                )}

                {grids
                    ?.filter((g) => g.slot === 'bottom')
                    .map((grid) => (
                        <GridLayoutRenderer key={`bottom_${grid.directusId}`} pageContext={pageContext} rootItem={grid} />
                    ))}
            </MainLayout>
        )
    }
}

export default withTranslations(ProductPageTemplate)

interface ProductPageProps extends Translation {
    pageContext: PageContext<IProductTemplateData>
}

interface ProductPageState {}
