import * as React from 'react'
import TypeBarItem from '../../../types/BarItem'
import * as styles from './AvailabilityBar.module.scss'
import BarItem from './BarItem'

const AvailabilityBar: React.FunctionComponent<AvailabilityBarProps> = (props: AvailabilityBarProps) => {
    const { barItems } = props

    return (
        <div className={styles.wrapper}>
            <div className={styles.barItemContainer}>
                {barItems.map((item, i) => (
                    <BarItem key={i} barItem={item} id={i} />
                ))}
            </div>
        </div>
    )
}

interface AvailabilityBarProps {
    barItems: TypeBarItem[]
}

export default AvailabilityBar
