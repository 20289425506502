import * as React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import BarItemType from '../../../types/BarItem'
import * as styles from './BarItem.module.scss'

const BarItem: React.FunctionComponent<BarItemProps> = (props: BarItemProps) => {
    const { disabled, variant, title, description } = props.barItem
    const { id } = props

    const iconClasses = `${disabled ? styles.disabled : ''} ${styles.icon} pti-${variant}`

    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Popover id={id} className={styles.popover}>
                    <Popover.Title as="h3">{title}</Popover.Title>
                    <Popover.Content>{description}</Popover.Content>
                </Popover>
            }
        >
            <span className={iconClasses} />
        </OverlayTrigger>
    )
}

interface BarItemProps {
    barItem: BarItemType
    id: string | number
}

export default BarItem
